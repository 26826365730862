<template>
  <el-dialog
    :title="lockFloor.id ? '编辑' : '新增'"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    append-to-body
    width="600px"
    top="5vh"
    @close="close">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="form"
        class="form"
        v-loading="readLoading"
        label-width="110px"
        :model="lockFloor">
        <el-form-item label="当前封层状态">
          <div>
            <el-button
              v-for="(item,index) in lockFloor.elevatorFloorList"
              :key="index"
              :style="stateIndexArray.includes(index) ? bindStyle : unBindStyle"
              style="margin-left: 0px"
              type="middle"
              disabled>
              {{item.displayValue}}
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <el-form
        ref="form"
        class="form"
        label-width="110px"
        style="margin-top: 60px"
        :model="lockFloor">
        <el-form-item label="封锁时段">
          <!--          <el-time-picker-->
          <!--            v-model="lockFloor.lockTimeList"-->
          <!--            is-range-->
          <!--            format="HH:mm"-->
          <!--            value-format="HH:mm"-->
          <!--            :range-separator="$t('common.to')"-->
          <!--            :start-placeholder="$t('common.beginTime')"-->
          <!--            :end-placeholder="$t('common.endTime')"-->
          <!--            :placeholder="$t('common.pleaseSelect')">-->
          <!--          </el-time-picker>-->
          <el-time-picker
            v-model="lockFloor.lockStartTime"
            style="width: 30%"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm:ss"
            :placeholder="$t('common.beginTime')">
          </el-time-picker>
          -
          <el-time-picker
            v-model="lockFloor.lockEndTime"
            style="width: 30%"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm:ss"
            :placeholder="$t('common.endTime')">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="封锁楼层">
          <el-button
            v-for="(item,index) in lockFloor.elevatorFloorList"
            :key="index"
            :style="indexArray.includes(index) ? bindStyle : unBindStyle"
            type="middle"
            @click="selectFloor(index)">
            {{item.displayValue}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
      <el-button type="primary" :loading="readLoading" @click="handleRead">
          获取当前封层
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        indexArray: [],
        stateIndexArray: [],
        submitLoading: false,
        readLoading: false,
        dialogVisible: false,
        contentLoading: false,
        bindStyle: {
          backgroundColor: "#ff4b00",
          color: "#fff",
          width: "50px",
        },
        unBindStyle: {
          width: "50px",
        },
        floorState: [],
        lockFloor: {
          id: 0,
          elevatorId: 0,
          lockStartTime: "",
          lockEndTime: "",
          lockFloor: "",
          lockDisplayFloor: "",
          elevatorFloorList: [],
        },
      };
    },
    methods: {
      open(elevatorId, row) {
        this.indexArray = [];
        this.dialogVisible = true;
        this.lockFloor.elevatorId = elevatorId;
        if (row) {
          if (row.id) {
            this.lockFloor.id = row.id;
            this.lockFloor.lockStartTime = row.lockStartTime;
            this.lockFloor.lockEndTime = row.lockEndTime;
            this.lockFloor.lockDisplayFloor = row.lockDisplayFloor;
          }
        }
        this.getFloor(elevatorId);
      },
      getFloorState(elevatorId) {
        this.readLoading = true;
        this.$http.get("lockFloor/getFloorState/" + elevatorId).then((res => {
          this.readLoading = false;
          this.floorState = res.data;
          if (this.floorState) {
            this.floorState.forEach(floor => {
              this.lockFloor.elevatorFloorList.forEach(item => {
                if (parseInt(item.displayValue) === floor) {
                  this.stateIndexArray.push(item.index);
                }
              });
            });
          }
        })).catch(() => {
          this.readLoading = false;
        });
      },
      getFloor(elevatorId) {
        this.$http.get("lockFloor/getFloor/" + elevatorId).then((res => {
          this.lockFloor.elevatorFloorList = res.data;

          this.getFloorState(elevatorId);

          if (this.lockFloor.id && this.lockFloor.lockDisplayFloor) {
            let array = this.lockFloor.lockDisplayFloor.split(",");
            array.forEach(floor => {
              this.lockFloor.elevatorFloorList.forEach(item => {
                if (item.displayValue === floor) {
                  this.indexArray.push(item.index);
                }
              });
            });
          }
        }));
      },
      selectFloor(index) {
        if (this.indexArray.indexOf(index) === -1) {
          this.indexArray.push(index);
        } else {
          this.indexArray.splice(this.indexArray.indexOf(index), 1);
        }
      },
      handleRead() {
        this.getFloorState(this.lockFloor.elevatorId);
      },
      handleSubmit() {
        if (!this.lockFloor.lockStartTime || !this.lockFloor.lockEndTime) {
          this.$message.error("请选择封锁时段");
          return;
        }
        if (this.indexArray.length === 0) {
          this.$message.error("请选择封锁楼层");
          return;
        }

        let lockFloor = [];
        let displayFloor = [];
        this.indexArray.sort();
        this.indexArray.forEach(index => {
          this.lockFloor.elevatorFloorList.forEach(item => {
            if (item.index === index) {
              displayFloor.push(item.displayValue);
              lockFloor.push(item.currentFloorCode);
            }
          });
        });
        this.lockFloor.lockDisplayFloor = displayFloor.join(",");
        this.lockFloor.lockFloor = lockFloor.join(",");

        console.log(this.lockFloor);
        this.submitLoading = true;
        this.$http.save("lockFloor", this.lockFloor)
          .then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
            this.close();
          })
          .catch(() => {
            this.submitLoading = false;
          });
      },
      close() {
        this.dialogVisible = false;
        this.lockFloor.lockStartTime = "";
        this.lockFloor.lockEndTime = "";
        this.lockFloor.id = 0;
        this.lockFloor.lockDisplayFloor = "";
        this.indexArray = [];
      },
    },
  };
</script>

<style lang="scss" scoped>
.form {
  ::v-deep .el-button {
    margin-left: 0px;
    margin-right: 3px;
  }
}
</style>
